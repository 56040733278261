<template>
  <div>
    <Header :breadcrumb="$options.ADDWINE_BREADCRUMBS" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/atoms/Header'
import { ADDWINE_BREADCRUMBS } from '@/constants/breadcrumb'

export default {
  ADDWINE_BREADCRUMBS,
  components: {
    Header,
  },
}
</script>
